import { Component, Inject, OnInit } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatRipple } from '@angular/material/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTabsModule } from '@angular/material/tabs'
import { DomSanitizer } from '@angular/platform-browser'
import { InputComponent } from '../../../components/input/input'
import { CustomSnackBar } from '../../../components/snackbar/snackbar'
import { Loading } from '../../../globals'
import { readHTML } from '../../../helpers'
import { PublicService } from '../../../services'

@Component({
  selector: 'app-modal-tier',
  templateUrl: './modal-tier.html',
  styleUrls: ['./modal-tier.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    InputComponent,
    MatRipple,
  ],
})
export class ModalTierComponent implements OnInit {
  readonly readHTML = readHTML
  tab = 0
  tabList: any[] = []

  constructor(
    public dialogRef: MatDialogRef<ModalTierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public loading: Loading,
    public customSnackBar: CustomSnackBar,
    public publicService: PublicService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.tabList = this.data.tierList
      .filter((d: any) => d.is_active)
      .map((item: any, i: number) => {
        if (item.id == this.data.id) {
          this.tab = i
        }
        return item
      })
  }

  changeTab($tabIndex: any) {
    this.tab = $tabIndex
  }

  onClose(): void {
    this.dialogRef.close(true)
  }
}
