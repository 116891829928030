import { Component, Inject } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatRipple } from '@angular/material/core'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatTabsModule } from '@angular/material/tabs'
import { DomSanitizer } from '@angular/platform-browser'
import { InputComponent } from '../../../components/input/input'
import { Loading } from '../../../globals'
import { findTier, formatPrice, readHTML } from '../../../helpers'
import { PublicService } from '../../../services'
import { ModalTierComponent } from '../modal-tier/modal-tier'

@Component({
  selector: 'app-modal-my-tier',
  templateUrl: './modal-my-tier.html',
  styleUrls: ['./modal-my-tier.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatTabsModule,
    MatIconModule,
    InputComponent,
    MatRipple,
  ],
})
export class ModalMyTierComponent {
  readonly formatPrice = formatPrice
  readonly readHTML = readHTML

  tierList: any[] = []
  tier: any

  get color() {
    return this.data.order.customer.tier.color
  }

  constructor(
    public dialogRef: MatDialogRef<ModalMyTierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public sanitizer: DomSanitizer,
    public loading: Loading,
    public publicService: PublicService
  ) {}

  ngOnInit(): void {
    this.loading.start()
    this.publicService.getTiers({}).subscribe(res => {
      if (!res.is_error) {
        this.tierList = res.data.filter((d: any) => d.is_active)
        this.tier = findTier(this.tierList, this.data.customer.total_price || 0)
      }
      this.loading.stop()
    })
  }

  onClose(): void {
    this.dialogRef.close()
  }

  onConfirm(): void {
    this.dialogRef.close(true)
  }

  openTier() {
    this.dialog.open(ModalTierComponent, {
      data: {
        id: this.tier.current?.id,
        tierList: this.tierList,
      },
    })
  }
}
